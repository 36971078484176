const Noodl = require('@noodl/noodl-sdk')
import { useEffect } from 'react'

const Query = (props) => {
    const query = window.Rolder.libs.query
    const useQuery = window.Rolder.libs.rq.useQuery
    const { className, filters, pagination, options } = props

    const { queryKey, queryFn } = query.list({ className, filters, pagination, options })
    const { isLoading, data } = useQuery({ queryKey, queryFn, staleTime: 600000 })
    props.items(data?.items.map(d => Noodl.Object.create(d)))
    props.fetchedCount(data?.fetchedCount)
    props.totalCount(data?.totalCount)
    props.isLoading(isLoading)

    return (<div></div>)
}

const DataService = (props) => {
    const QueryClientProvider = window.Rolder.libs.rq.QueryClientProvider
    const queryClient = window.Rolder.libs.rq.client

    return (
        <QueryClientProvider client={queryClient}>
            <Query {...props} />
        </QueryClientProvider>
    )
}

const DataServiceNode = Noodl.defineReactNode({
    name: 'DataService v0.0.1',
    getReactComponent() {
        return DataService;
    },
    inputProps: {
        className: { type: 'string', displayName: 'Classname', description: 'Backend classname' },
        filters: { type: 'object', displayName: 'Filters', description: 'Filters object' },
        pagination: { type: 'object', displayName: 'Pagination', description: 'Pagination object' },
        options: { type: 'object', displayName: 'Options', description: 'Options: withCount(boolean)' },
    },
    outputProps: {
        items: { type: 'array', displayName: 'Items' },
        fetchedCount: { type: 'number', displayName: 'Fetched count' },
        totalCount: { type: 'number', displayName: 'Total count' },
        isLoading: { type: 'boolean', displayName: 'Is loading' }
    }
})

Noodl.defineModule({
    reactNodes: [DataServiceNode],
    nodes: [],
    setup() { }
})